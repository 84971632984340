<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <div class="basic-table">
              <div
                class="row basic-table__header no-gutters align-items-center"
              >
                <div class="col-4">
                  <h3 class="basic-table__title text-seizegreen">
                    매출 입금 집계
                  </h3>
                </div>
              </div>
              <div class="basic-table__table">
                <div class="row">
                  <div class="col-4">
                    <v-data-table
                      :headers="inputSumHeader"
                      :items="inputSumData"
                      class="seize-table-box"
                      dense
                      hide-default-footer
                      item-key="key"
                      loading-text="데이터 조회중입니다..."
                    >
                      <template v-slot:item.key="{ item }">
                        <span style="color: #000000; font-weight: bold;">{{
                          item.key
                        }}</span>
                      </template>
                      <template v-slot:item.count="{ item }">
                        <span style="color: #000000;">{{ item.count }}</span>
                      </template>
                      <template v-slot:item.sum="{ item }">
                        <span style="color: #000000;">{{ item.sum }}</span>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="col-4">
                    <Chart
                      ref="salesSumChart"
                      v-bind="{
                        options: sumChartOptions,
                        plugins: chartPlugin,
                      }"
                    ></Chart>
                  </div>
                  <div class="col-4">
                    <Chart
                      ref="salesCountChart"
                      v-bind="{
                        options: countChartOptions,
                        plugins: chartPlugin,
                      }"
                    ></Chart>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <input-table
              ref="salesInputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '매출 입금 상세',
                titleTextClass: 'text-seizegreen',
                detailData: detailInputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['입금'],
                itemsPerPage: 10,
              }"
            >
            </input-table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <output-table
              ref="salesOutputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '매출 출금 상세-예외',
                titleTextClass: 'text-seizegreen',
                detailData: detailOutputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['출금'],
                itemsPerPage: 10,
              }"
            >
            </output-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable.vue";
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Chart from "@/views/partials/widgets/Chart.vue";
import Vue from "vue";
import ChartDataLabels from "chartjs-plugin-datalabels";

Vue.use(SeizePlugin);
export default {
  name: "Sales",
  components: {
    KTPortlet,
    "input-table": TransactionDataTable,
    "output-table": TransactionDataTable,
    Chart,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
      categoryMapData: {},
      detailInputDataArray: this.getInputData(),
      detailOutputDataArray: this.getOutputData(),
      inputSumHeader: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
        {
          text: "합계",
          sortable: false,
          align: "right",
          class: "seize-table-header seize-table-box",
          value: "sum",
        },
      ],
      inputSumData: [],
      inputSumChartData: [],
      sumChartOptions: {},
      countChartOptions: {},
      chartPlugin: [ChartDataLabels],
    };
  },
  mounted() {
    document.title = "매출";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.inputSumData = this.generateInputSumData();
    this.inputSumChartData = this.generateInputSumChartData();

    const sumChartData = {
      labels: this.makeChartLabel(this.inputSumChartData),
      datasets: this.makeSumChartData(
        this.inputSumChartData,
        "금액",
        "rgb(27,197,189)"
      ),
    };
    this.sumChartOptions = {
      type: "bar",
      data: sumChartData,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            color: "rgb(0,0,0)",
            font: {
              weight: "bold",
            },
            formatter: function (value) {
              return numeral(value / 1000).format("0,0") + "천원";
            },
          },
        },
        title: {
          display: true,
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              const value = data.datasets[0].data[tooltipItems.index];
              return numeral(value / 1000).format("0,0") + "천원";
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontStyle: "bold",
                fontColor: "rgb(27,197,189)",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: "left",
              gridLines: {
                display: true,
                color: "transparent",
                lineWidth: 0,
                zeroLineWidth: 3,
                zeroLineColor: "rgb(217,217,217)",
              },
              ticks: {
                display: false,
                beginAtZero: true,
                callback: function (value) {
                  return numeral(value).format("0,0");
                },
              },
            },
          ],
        },
      },
    };
    const countChartData = {
      labels: this.makeChartLabel(this.inputSumChartData),
      datasets: this.makeCountChartData(
        this.inputSumChartData,
        "건수",
        "rgb(27,197,189)"
      ),
    };
    this.countChartOptions = {
      type: "bar",
      data: countChartData,
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            anchor: "end",
            align: "end",
            color: "rgb(0,0,0)",
            font: {
              weight: "bold",
            },
            formatter: function (value) {
              return numeral(value).format("0,0") + "건";
            },
          },
        },
        title: {
          display: true,
        },
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              const value = data.datasets[0].data[tooltipItems.index];
              return numeral(value).format("0,0") + "건";
            },
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                fontStyle: "bold",
                fontColor: "rgb(27,197,189)",
              },
            },
          ],
          yAxes: [
            {
              display: true,
              position: "left",
              gridLines: {
                display: true,
                color: "transparent",
                lineWidth: 0,
                zeroLineWidth: 3,
                zeroLineColor: "rgb(217,217,217)",
              },
              ticks: {
                display: false,
                beginAtZero: true,
                callback: function (value) {
                  return numeral(value).format("0,0");
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.$refs.salesInputTable.setData(this.baseDate, this.getInputData());
      this.$refs.salesOutputTable.setData(this.baseDate, this.getOutputData());
      this.inputSumData = this.generateInputSumData();
      this.inputSumChartData = this.generateInputSumChartData();
      this.sumChartOptions.data.labels = this.makeChartLabel(
        this.inputSumChartData
      );
      this.sumChartOptions.data.datasets = this.makeSumChartData(
        this.inputSumChartData,
        "금액",
        "rgb(27,197,189)"
      );
      this.countChartOptions.data.labels = this.makeChartLabel(
        this.inputSumChartData
      );
      this.countChartOptions.data.datasets = this.makeCountChartData(
        this.inputSumChartData,
        "건수",
        "rgb(27,197,189)"
      );
      this.$refs.salesSumChart.redrawChart();
      this.$refs.salesCountChart.redrawChart();
    },
    generateInputSumData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      let inputList = this.getInputCategoryData(categoryMap);

      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(inputList);
      return this.getSimpleResult(monthBaseData);
    },
    getSimpleResult(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: numeral(before1M_count).format("0,0") + "건",
        sum: numeral(before1M_value).format("0,0") + "원",
      };
      const b2MData = {
        key: "2M",
        count: numeral(before2M_count).format("0,0") + "건",
        sum: numeral(before2M_value).format("0,0") + "원",
      };
      const b3MData = {
        key: "3M",
        count: numeral(before3M_count).format("0,0") + "건",
        sum: numeral(before3M_value).format("0,0") + "원",
      };
      const sumData = {
        key: "합계",
        count:
          numeral(before1M_count + before2M_count + before3M_count).format(
            "0,0"
          ) + "건",
        sum:
          numeral(before1M_value + before2M_value + before3M_value).format(
            "0,0"
          ) + "원",
      };
      const avgData = {
        key: "평균",
        count:
          numeral(
            Math.round((before1M_count + before2M_count + before3M_count) / 3)
          ).format("0,0") + "건",
        sum:
          before1M_count + before2M_count + before3M_count === 0
            ? "0원"
            : numeral(
                Math.round(
                  (before1M_value + before2M_value + before3M_value) /
                    (before1M_count + before2M_count + before3M_count)
                )
              ).format("0,0") + "원",
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(sumData);
      result.push(avgData);
      return result;
    },
    generateInputSumChartData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      let inputList = this.getInputCategoryData(categoryMap);

      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToDateString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === 1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(inputList);
      return this.getSimpleResultValueOnly(monthBaseData);
    },
    getSimpleResultValueOnly(values) {
      const result = [];
      const baseDate = this.baseDate;
      const before1M = Vue.getBeforeMonthString(baseDate, 1);
      const before2M = Vue.getBeforeMonthString(baseDate, 2);
      const before3M = Vue.getBeforeMonthString(baseDate, 3);
      let before1M_count = 0,
        before1M_value = 0,
        before2M_count = 0,
        before2M_value = 0,
        before3M_count = 0,
        before3M_value = 0;
      values.forEach((element) => {
        if (element.key <= baseDate && element.key > before1M) {
          before1M_count += element.value.count;
          before1M_value += element.value.sum;
        }
        if (element.key <= before1M && element.key > before2M) {
          before2M_count += element.value.count;
          before2M_value += element.value.sum;
        }
        if (element.key <= before2M && element.key > before3M) {
          before3M_count += element.value.count;
          before3M_value += element.value.sum;
        }
      });
      const b1MData = {
        key: "1M",
        count: before1M_count,
        sum: before1M_value,
      };
      const b2MData = {
        key: "2M",
        count: before2M_count,
        sum: before2M_value,
      };
      const b3MData = {
        key: "3M",
        count: before3M_count,
        sum: before3M_value,
      };
      const avgData = {
        key: "평균",
        count: Math.round(
          (before1M_count + before2M_count + before3M_count) / 3,
          2
        ),
        sum:
          before1M_count + before2M_count + before3M_count === 0
            ? 0
            : Math.round(
                (before1M_value + before2M_value + before3M_value) /
                  (before1M_count + before2M_count + before3M_count)
              ),
      };
      result.push(b1MData);
      result.push(b2MData);
      result.push(b3MData);
      result.push(avgData);
      return result;
    },
    makeChartLabel(rawData) {
      const result = [];
      rawData.forEach((element) => {
        result.push(element.key);
      });
      return result;
    },
    makeSumChartData(rawData, label, color) {
      const result = [];
      const dataSets = {
        label: label,
        backgroundColor: color,
        barThickness: 50,
        maxBarThickness: 55,
      };
      const data = [];
      rawData.forEach((element) => {
        data.push(element.sum);
      });
      dataSets.data = data;
      result.push(dataSets);
      return result;
    },
    makeCountChartData(rawData, label, color) {
      const result = [];
      const dataSets = {
        label: label,
        backgroundColor: color,
        barThickness: 50,
        maxBarThickness: 55,
      };
      const data = [];
      rawData.forEach((element) => {
        data.push(element.count);
      });
      dataSets.data = data;
      result.push(dataSets);
      return result;
    },

    getInputData() {
      this.categoryMapData = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      return this.getInputCategoryData(this.categoryMapData);
    },
    getOutputData() {
      return this.getOutputCategoryData(this.categoryMapData);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    getInputCategoryData(categoryMap) {
      let result = [];
      const codeList = ["DPICBIC00080072", "DPICBIC00080000"];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          categoryMap.get(element).forEach((item) => {
            if (item.inout_code === 1) {
              result.push(item);
            }
          });
      });
      return result;
    },
    getOutputCategoryData(categoryMap) {
      let result = [];
      const codeList = ["WDICBIC00080000"];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          categoryMap.get(element).forEach((item) => {
            if (item.inout_code === -1) {
              result.push(item);
            }
          });
      });
      return result;
    },
  },
};
</script>
